<template>
  <div class="pt-3">
    <Loader v-if="!group || !group.id" />
      <div class="dashboard__container--body">
        <div class="dashboard__container--body--col">
          <div>
          <form ref="form" @submit.prevent>
            <div class="mb-3">
              <label for="title">Job Posting Title:</label>
              <input type="text" v-model.trim="group.title" id="title" />
            </div>
            <div class="mb-3">
              <label for="pay">Pay Starting At:</label>
              <input type="text" v-model.trim="group.startingPay" id="pay" />
            </div>
            <div class="mb-3">
              <label for="groupVisible">Visible on Website:</label>
              <input type="checkbox" v-model.trim="group.visible" id="groupVisible" class="ml-3" />
            </div>
            <div class="mb-3">
              <label for="grouprequiresEmployees">Requires Employees:</label>
              <input type="checkbox" v-model.trim="group.requiresEmployees" id="grouprequiresEmployees" class="ml-3" />
            </div>
            <div class="mb-3">
              <label for="groupAmazon">Ask if they've worked at Amazon:</label>
              <input type="checkbox" v-model.trim="group.amazon" id="groupAmazon" class="mb-5 ml-3" />
            </div>
            <!-- <div class="mb-3">
              <label for="location">Location (Ex: Monterey, CA):</label>
              <input type="text" v-model.trim="group.location" id="location" />
            </div> -->
            <div class="mb-3">
              <label for="location">Location: {{group.location}}</label>
              <label>
                <gmap-autocomplete
                  @place_changed="setPlace" :options="{fields: ['geometry', 'address_components']}" placeholder="Edit Location">
                </gmap-autocomplete>
              </label>
            </div>

            <div class="mb-3" v-if="!group.visible">
              <label for="customer">Link with Customers:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="clients"
                v-model="group.customer"
                multiple
                >
              </v-select>
            </div>

            <div class="mb-3" v-if="!group.visible">
              <label for="veneu">Link with Venues:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="venues"
                v-model="group.venue"
                multiple
                >
              </v-select>
            </div>

            <div class="mb-3">
              <label for="pickDate">Specify Work Type:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="types"
                v-model="group.type"
                multiple
                >
              </v-select>
            </div>

            <div class="mb-3">
              <label for="pickDate">Specify Jobs:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="jobs"
                v-model="group.job"
                multiple
                >
              </v-select>
            </div>
            
            <div class="mb-3">
              <label for="desc">Group Description:</label>
              <!-- <textarea name="desc" id="desc" cols="30" rows="6" v-model="group.description"></textarea> -->
              <vue-editor id="desc" v-model="group.description" required></vue-editor>
            </div>
            <button class="btn btn__primary mt-2" @click="updateGroup()">Update
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </form>
          </div>
        </div>
        <div class="dashboard__container--body--col">
          <div>
          <form ref="form" @submit.prevent>
            <div class="mb-3" v-if="(reps.length > 1)">
              <label for="rep">Account Admins:</label>
              <v-select
                class="mt-2"
                label="name" 
                :options="reps"
                v-model="group.admins"
                multiple
                >
              </v-select>
            </div>
            <div class="mb-3" v-if="(reps.length > 1) && (isOwner || isAdmin)">
              <label for="rep">Account Users:</label>
              <v-select
                class="mt-2"
                label="name" 
                :options="reps"
                v-model="group.users"
                multiple
                >
              </v-select>
            </div>
            <div class="mb-3">
              <button class="btn btn__primary mt-2" @click="addUsers()">Update Users
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </div>
            
          </form>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import { VueEditor } from "vue2-editor";
import * as moment from 'moment'

export default {
  name: 'groupEdit',
  data: () => ({
    message: '',
    showAll: true,
    performingRequest: false,
    performingRequest2: false,
    marker: {},
    address: {},
    place: '',
    currentPlace: null,
    types: ['Full Time', 'Part Time', 'On-Call']
  }),
  computed: {
    ...mapState(['group', 'currentUser', 'userProfile', 'mgrs', 'jobs', 'clients', 'venues']),
    clean() {
      return (!this.groupUsers || this.groupUsers.length == 0)
    },
    reps: function() {
      return this.mgrs.filter(mgr => {
        return mgr.userId
      })
    },
    isOwner() {
      return this.group.owner == this.currentUser.uid
    },
    isAdmin() {
      return (this.group.admins && this.group.admins.some(person => person.userId == this.currentUser.uid))
    },
    isUser() {
      return (this.group.users && this.group.users.some(person => person.userId == this.currentUser.uid))
    }
  },
  created () {
    // this.$store.dispatch("getGroupFromId", this.$route.params.id);
    if (!this.mgrs || this.mgrs.length < 1) {
      this.$store.dispatch("getMgrsState")
    }
    if (!this.jobs || this.jobs.length < 1) {
      this.$store.dispatch("getJobsState")
    }
    if (!this.venues || this.venues.length < 1) {
      this.$store.dispatch("getVenues")
    }
    if (!this.clients || this.clients.length < 1) {
      this.$store.dispatch("getClients")
    }
  },
  components: {
    Loader,
    VueEditor,
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality' || component.types[0] == 'sublocality_level_1' || component.types[0] == 'natural_feature') {
              this.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.address.zip = component['short_name'];
          }
        }
      }
    },
    updateGroup() {
      this.performingRequest = true
      let group = this.group
      if (this.center) {
        group.center = this.center
      }
      if (this.address.city && this.address.state) {
        group.location = this.address.city + ', ' + this.address.state
      }
      this.$store.dispatch('updateGroup', group)
      setTimeout(() => {
        this.performingRequest = false
      }, 500)
    },
    addUsers() {
      this.performingRequest2 = true
      let group = this.group
      this.$store.dispatch('updateGroup', group)
      setTimeout(() => {
        this.performingRequest2 = false
      }, 500)
    },
    deleteGroup() {
      let group = this.group
      this.$store.dispatch('deleteGroup', group)
      setTimeout(() => {
        let url = `/groups`
        router.push(url)
      }, 500)
    },
    // addUser() {

    // },
    // goBack() {
    //   router.go(-1)
    // },
  },

  beforeDestroy () {
    this.$store.dispatch('clearJobsState')
    this.$store.dispatch('clearVenuesState')
    this.$store.dispatch('clearClientsState')
    this.message = null
    delete this.message
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest2 = null
    delete this.performingRequest2
    this.marker = null
    delete this.marker
    this.address = null
    delete this.address
    this.place = null
    delete this.place
    this.currentPlace = null
    delete this.currentPlace
    this.types = null
    delete this.types

    delete this.deleteGroup
    delete this.addUsers
    delete this.updateGroup
    delete this.setPlace
  }
}
</script>